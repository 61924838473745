{
  "lang_markread": "標記為已讀",
  "lang_newest": "最新",
  "lang_unread": "未讀",
  "lang_starred": "已加星標",
  "lang_tags": "標籤",
  "lang_alltags": "所有標籤",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 小時},other {# 小時}}前",
  "lang_minutes": "{0,plural,one {1 分鐘},other {# 分鐘}}前",
  "lang_seconds": "{0,plural,one {1 秒},other {# 秒}}前",
  "lang_days": "{0,plural,zero {今天},one {昨天},other {# 天前}}",
  "lang_star": "加星標",
  "lang_unstar": "取消星標",
  "lang_mark": "標記為已讀",
  "lang_unmark": "標記為未讀",
  "lang_load_img": "顯示圖片",
  "lang_open_window": "開啟",
  "lang_searchbutton": "搜尋",
  "lang_sources": "來源",
  "lang_source_add": "新增來源",
  "lang_source_opml": "或匯入一個 OPML 檔案",
  "lang_source_export": "匯出來源",
  "lang_source_edit": "編輯",
  "lang_source_delete": "刪除",
  "lang_source_warn": "真的刪除這個來源嗎？",
  "lang_source_new": "新的未儲存來源",
  "lang_source_title": "標題",
  "lang_source_tags": "標籤",
  "lang_source_comma": "以逗號分隔",
  "lang_source_select": "請選擇來源",
  "lang_source_type": "類型",
  "lang_source_save": "儲存",
  "lang_source_cancel": "取消",
  "lang_source_saved": "已儲存",
  "lang_source_last_post": "更新於",
  "lang_no_entries": "找不到條目",
  "lang_more": "更多",
  "lang_login": "登入",
  "lang_login_username": "使用者名稱",
  "lang_login_password": "密碼",
  "lang_no_title": "無標題",
  "lang_error_saving_color": "無法儲存新顏色：",
  "lang_error_share_native_abort": "無法分享項目—沒有分享目標，或者你已取消它。",
  "lang_article_actions": "動作",
  "lang_error_check_log_file": "發生錯誤，請檢查日誌檔案「{0}」。",
  "lang_filter": "過濾器",
  "lang_share_diaspora_label": "在 Diaspora 上分享",
  "lang_error_add_source": "無法新增來源：",
  "lang_share_copy_label": "複製連結至剪貼簿",
  "lang_sources_refreshed": "來源已重新整理",
  "lang_app_reload": "重新載入",
  "lang_error_unknown_source": "未知的來源 ID：",
  "lang_error_mark_item": "無法標記／取消標記項目：",
  "lang_error_refreshing_source": "無法重新整理來源：",
  "lang_error_sync": "無法從伺服器同步最後的變更：",
  "lang_error_offline_storage_not_available": "離線儲存不可用。確保你的網頁瀏覽器{0}支援 IndexedDB API{1}，並且如果你正在使用的是基於 Google Chrome 的瀏覽器，請確保你沒有在無痕模式下執行 selfoss。",
  "lang_error_invalid_subsection": "無效的小節：",
  "lang_error_configuration": "無法載入配置。",
  "lang_error_share_native": "無法分享項目。",
  "lang_info_url_copied": "網址已複製至剪貼簿。",
  "lang_close_entry": "關閉",
  "lang_online_count": "伺服器上可用的項目",
  "lang_searchremove": "隱藏搜尋",
  "lang_offline_count": "本地可用的項目",
  "lang_offline_navigation_unavailable": "目前在離線模式下無法切換標籤和來源。",
  "lang_settingsbutton": "設定",
  "lang_logoutbutton": "登出",
  "lang_source_filter": "過濾器",
  "lang_share_twitter_label": "在 Twitter 上分享",
  "lang_share_facebook_label": "在 Facebook 上分享",
  "lang_next": "下一個",
  "lang_share_mastodon_label": "在 Mastodon 上分享",
  "lang_share_pocket_label": "儲存至 Pocket",
  "lang_refreshbutton": "重新整理來源",
  "lang_share_wallabag_label": "儲存至 Wallabag",
  "lang_share_wordpress_label": "在 WordPress 上分享",
  "lang_share_mail_label": "使用電子郵件分享",
  "lang_loginbutton": "登入",
  "lang_source_autotitle_hint": "留空以自動獲取標題",
  "lang_source_saving": "正在儲存來源變更…",
  "lang_source_pwd_placeholder": "沒有變更",
  "lang_source_go_to_settings": "在設定中開啟",
  "lang_source_refresh": "重新整理這個來源",
  "lang_error_edit_source": "無法儲存來源變更：",
  "lang_search_label": "搜尋詞語",
  "lang_login_invalid_credentials": "錯誤的使用者名稱／密碼",
  "lang_login_offline": "離線儲存",
  "lang_error": "發生錯誤",
  "lang_streamerror": "沒有載入任何項目，重新載入嗎？",
  "lang_reload_list": "重新載入列表",
  "lang_app_update": "selfoss 已更新，請重新載入",
  "lang_error_session_expired": "工作階段已過期",
  "lang_error_delete_source": "無法刪除來源：",
  "lang_error_load_tags": "無法載入標籤：",
  "lang_error_unknown_tag": "未知的標籤：",
  "lang_error_mark_items": "無法標記所有可見項目：",
  "lang_error_star_item": "無法加星標／取消星標項目：",
  "lang_error_logout": "無法登出：",
  "lang_error_loading": "載入錯誤：",
  "lang_error_loading_stats": "無法載入導航統計資料：",
  "lang_error_offline_storage": "離線儲存錯誤：{0}。重新載入可能會有所幫助。暫時停用離線功能。",
  "lang_share_native_label": "分享",
  "lang_sources_loading": "正在載入來源…",
  "lang_no_sources": "沒有已配置的來源。",
  "lang_source_menu": "更多動作",
  "lang_source_browse": "瀏覽",
  "lang_source_deleting": "正在刪除來源…",
  "lang_source_warn_cancel_dirty": "放棄未儲存的變更？",
  "lang_source_params_loading": "正在載入參數…",
  "lang_tag_change_color_button_title": "變更顏色",
  "lang_entries_loading": "正在載入條目…",
  "lang_login_in_progress": "正在嘗試登入…",
  "lang_login_error_generic": "無法登入：{errorMessage}",
  "lang_experimental": "實驗性",
  "lang_error_unauthorized": "請{link_begin}登入{link_end}以繼續。",
  "lang_error_check_system_logs": "發生錯誤，請檢查你的系統日誌。",
  "lang_error_unwritable_logs": "無法寫入日誌。",
  "lang_article_reading_time": "{0} 分鐘閱讀",
  "lang_sources_leaving_unsaved_prompt": "未儲存來源變更，離開設定？"
}
